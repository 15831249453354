@import "../../styles/variables";
@import "../../styles/mixins";

.accommodationContainer {
  display: flex;
  flex-direction: row;
  min-height: 45px;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0 12px;
  cursor: pointer;

  .valueContainer {
    display: flex;
    gap: 8px;

    .peopleIcon {
      color: $light-green;
    }

    .value {
      font-family: $robotoFont;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &:hover {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.09);
  }
}

.popoverContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 10px;

  .popoverValueContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    height: 40px;

    .peopleIcon {
      color: $light-green;
    }

    .controlContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 150px;

      .countContainer {
        font-family: $robotoFont;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .buttonControl {
        min-width: 30px;
        padding: 11px;
        width: 30px;
        height: 30px;
      }
    }
  }

  .ageSelectContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 10px;
  }
}