@import "../../styles/variables";
@import "../../styles/mixins";

.socials {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @include bp(m) {
    margin-left: 8px;
  }

  a {
    width: 24px;
    color: $bright-color;

    @include bp(m) {
      width: 28px;
    }
  }
}
