@import "../../styles/variables";
@import "../../styles/mixins";

.textField {
  width: 100%;

  :global(.MuiAutocomplete-inputRoot) {
    padding-right: 9px !important;
  }

  :global(.MuiAutocomplete-root) {
    width: 100%;
  }

  :global(.MuiInputBase-root) {
    background-color: transparent;
  }

  :global(.MuiInputBase-root:hover) {
    border-radius: 8px;
  }

  &.currencyInput {
    p {
      margin: 1px 0 0;
      line-height: 1.5;
      font-family: $globalFont;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 8px 14px 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  &.timeInput {
    :global(.MuiFormHelperText-sizeMedium) {
      position: absolute;
      top: 50px;
    }
  }

  label {
    font-family: $globalFont;
    color: $label;
    font-size: 14px;
    line-height: 1.4374em;
    font-weight: 400;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;

    &:global(.Mui-focused) {
      color: $label-focused;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 8px;
    font-size: 14px;
    font-family: $globalFont;
    font-weight: 400;
    line-height: 1.4375;

    input {
      font-family: $globalFont;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $globalFont;
    margin: 8px 14px 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }
}

.switcherContainer {
  margin-top: 10px;

  :global(.MuiSwitch-switchBase) {
    color: $red-color;
  }

  :global(.Mui-checked) {
    color: rgb(0, 171, 85) !important;
  }

  :global(.Mui-checked+.MuiSwitch-track) {
    background-color: rgb(0, 171, 85) !important;
  }

  :global(.MuiSwitch-track) {
    background-color: $red-color;
  }

  :global(.MuiFormControlLabel-label) {
    line-height: 1.57143;
    font-size: 14px;
    font-family: $globalFont;
    font-weight: 400;
  }
}

.checkboxContainer {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;

  :global(.MuiSvgIcon-root) {
    width: 16px;
    height: 16px;
  }

  :global(.MuiFormControlLabel-label) {
    font-family: $robotoFont;
    font-weight: 300;
    color: $primary-extra-dark;
    font-size: 16px;
  }

  :global(.Mui-checked) {
    color: $primary-extra-dark !important;
  }

  &:hover {
    background-color: $very-light-green;
  }
}

.editorContainer {
  &.error {
    div:first-of-type {
      border-color: $red-color;
    }
  }

  .editorError {
    font-family: $globalFont;
    font-weight: 400;
    font-size: 0.75rem;
    color: $red-color;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 8px 14px 0;
  }
}

.iconSelect {
  margin-right: 18px;
  color: $primary-main;
}

.dateLabelContainer {
  padding: 16px 16px 0 24px;
  color: $primary-main;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}