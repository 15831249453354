@import "../../styles/mixins";
@import "../../styles/variables";

.switcherButton {
  color: $bright-color;
  font-family: $poppinsFont;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  margin-right: 10px;

  @include bp(m) {
    margin-left: 30px;
    margin-right: 0;
  }

  svg {
    margin-left: 6px;
  }
}

.popover {
  :global(.MuiPaper-root) {
    background: transparent;
  }
}

.popoverContent {
  background-color: $primary-button-background;
  color: $bright-color;
  background-image: none;
  overflow: hidden auto;
  min-height: 16px;
  max-height: calc(100% - 32px);
  outline: 0;
  max-width: 100%;
  opacity: 1;
  transform: none;
  transition: opacity 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .popoverList {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    outline: 0;

    .popoverListItem {
      list-style: none;
      background-color: transparent;
      outline: 0;
      border: 0;
      margin: 0;
      border-radius: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      line-height: 1.5;
      font-size: 1rem;
      font-family: $poppinsFont;
      font-weight: 400;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      text-decoration: none;
      padding: 6px 16px;
      box-sizing: border-box;
      white-space: nowrap;
      color: $bright-color;
      width: 50px;

      &:hover {
        background-color: $primary-button-background-hover;
      }

      &.disabled {
        cursor: not-allowed;
        color: rgba(99, 115, 129, 0.5);

        &:hover {
          background-color: transparent;
        }
      }

      .popoverIcon {
        flex-shrink: 0;
        display: inline-flex;
        color: $bright-color;
        margin-right: 16px;
      }

      .popoverItemText {
        flex: 1 1 auto;
        min-width: 0;
      }
    }
  }
}