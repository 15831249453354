@import '../../styles/variables';
@import '../../styles/mixins';

.navigationContainer {
  display: flex;
  align-items: center;
  color: $primary-main;
  padding: 1.25rem 1.125rem;
  z-index: 8;
  background-color: $primary-main;
  justify-content: space-between;
  width: 100%;
  height: 4.5rem;

  @include bp(m) {
    justify-content: center;
  }

  @include bp(l) {
    padding: 0 9rem;
  }

  .logo {
    svg {
      color: $bright-color;
    }

    img {
      height: 1.5rem;
    }
  }

  .iconItems {
    display: flex;
    align-items: center;

    .drawerIcon {
      color: $bright-color;
    }
  }

  .navigationContainer {
    display: flex;
    flex-direction: row;
    gap: 1.25rem;

    @include bp(l) {
      gap: 2.5rem;
    }

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 0.938rem;
      line-height: 1.125rem;
      color: $bright-color;

      &:hover {
        text-shadow: 0.125rem 0.125rem 0.25rem $black-color;
      }
    }
  }

  .actionContainer {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;

    .search {
      background-color: $bright-color;
      color: $black-color;

      &:hover {
        background-color: $color-grey-light;
      }
    }

    button {
      height: 2.5rem;
      width: 100%;
      font-family: $poppinsFont;
      font-style: normal;
      font-weight: 600;
      font-size: 0.938rem;
      line-height: 1.5rem;
    }
  }

  .mobileMenu {
    top: 4.313rem;
    left: 0;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100% - 4.313rem);
    overflow: scroll;
    align-items: center;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    z-index: 8;
    box-shadow: rgba(34, 51, 84, 0.2) 0 0.125rem 0.5rem -0.188rem,
      rgba(34, 51, 84, 0.1) 0rem 0.313rem 1.375rem -0.25rem;
    background-color: $primary-main;

    .navigationItem {
      display: flex;
      width: 100%;
      padding: 0.875rem 1.5rem;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      outline: 0;
      border: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-family: $globalFont;
      min-width: 4rem;
      border-radius: 0;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      text-transform: none;
      margin: 0 0 0.25rem;
      font-style: normal;
      font-weight: 400;
      font-size: 0.938rem;
      line-height: 1.125rem;
      color: $bright-color;

      &:hover {
        background-color: $primary-dark;
      }

      &.activeSubItem {
        background-color: $primary-dark;
        color: $bright-color;
        font-weight: 600;
      }

      .navigationIcon {
        margin-right: 0.625rem;
      }
    }
  }

  .navigationMenu {
    display: flex;
    list-style: none;
    gap: 1.25rem;

    @include bp(l) {
      gap: 2.5rem;
    }

    ul {
      list-style: none;
      padding-inline-start: 0rem;
    }

    > li,
    > a {
      display: inline-block;
    }

    li {
      position: relative;
    }

    ul a {
      display: block;
      text-decoration: none;
      padding: 1.563rem;
      color: $bright-color;
      transition: 0.2s;

      :hover {
        text-shadow: 0.125rem 0.125rem 0.25rem $black-color;
      }
    }

    .subMenu {
      display: none;
      position: absolute;
      background-color: $primary-main;
      width: 14rem;
      left: -1.563rem;
      padding-top: 1.63rem;
      border-radius: 0.75rem;

      li {
        a {
          display: flex;
          justify-content: space-between;
        }

        &:last-child {
          border-bottom-left-radius: 0.75rem;
          border-bottom-right-radius: 0.75rem;
        }

        &:hover {
          background-color: $primary-light;
        }
      }

      .rightIcon {
        margin-left: 0.5rem;
      }
    }

    .subMenuSecond {
      display: none;
      position: absolute;
      top: 0;
      left: 100%;
      width: 14rem;
      background-color: $primary-main;
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }

    li:hover > .subMenu {
      display: block;
      animation: slideUp 0.2s ease;
    }

    li:hover > .subMenuSecond {
      display: block;
      animation: slideLeft 0.2s ease;
    }

    @keyframes slideUp {
      0% {
        opacity: 0;
        transform: translateY(1.125rem);
      }

      100% {
        opacity: 1;
        transform: none;
      }
    }

    @keyframes slideLeft {
      0% {
        opacity: 0;
        transform: translateX(0.625rem);
      }

      100% {
        opacity: 1;
        transform: none;
      }
    }
  }

  .openSubMenuButton {
    padding: 0.125rem 0.375rem;
    color: $bright-color;
    border-radius: 0.313rem;
    margin-left: 0.313rem;

    .openSubMenuIcon {
      font-size: 0.8rem;
    }
  }

  .mobileNavigationItemContainer {
    width: 100%;

    .mobileNavigationLocationGroupContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $bright-color;

      a {
        padding-left: 3.375rem !important;
      }
    }

    .mobileNavigationSubMenuTitleContainer {
      width: 100%;
      padding: 0.875rem 1.5rem 0.875rem 2.125rem;
      cursor: pointer;
      user-select: none;
      font-family: $globalFont;
      font-style: normal;
      font-weight: 400;
      font-size: 0.938rem;
      line-height: 1.125rem;

      &:hover {
        background-color: $primary-dark;
      }
    }

    .mobileNavigationLocationsWithoutGroupsContainer {
      a {
        padding-left: 2.125rem !important;
      }
    }
  }
}
