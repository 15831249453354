@import "../../styles/variables";
@import "../../styles/mixins";

.footerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $primary-extra-dark;

  .footer {
    width: 100%;
    padding: 30px 20px 24px;

    @include bp(s) {
      padding: 30px 40px 24px;
    }

    @include bp(l) {
      padding: 50px 240px 24px;
    }

    .footerContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .logoSectionContainer {
        @include bp(xs) {
          width: 100%;
        }

        @include bp(l) {
          width: auto;
        }

        .logoSection {
          display: flex;
          width: 100%;
          margin-bottom: 20px;
          align-items: flex-start;
          flex-direction: column;

          @include bp(xs) {
            flex-direction: row;
            align-items: center;
          }

          svg {
            color: $bright-color;
          }
        }
      }

      .footerItemContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        gap: 18px;

        @include bp(l) {
          flex-direction: row;
        }

        .footerItemTitle {
          font-family: $poppinsFont;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          margin-top: 40px;
          margin-bottom: 20px;
          color: $color-orange;

          @include bp(l) {
            margin-top: 40px;
          }
        }

        .footerItem {
          font-family: $robotoFont;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 24px;
          color: $bright-color;
        }

        .socials {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;

          a {
            width: 32px;
          }
        }

        a {
          display: flex;

          svg {
            color: $bright-color;
          }
        }
      }
    }

    .copyright {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 30px;
      font-family: $robotoFont;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: $bright-color;
      gap: 10px;

      img {
        height: 18px;
      }
    }
  }
}